import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { baseParamState } from '../../shared/constants.js';
import { playEditorActions } from '../play-editor/index.js';

const createParamUpdater =
    <K extends keyof RouterState>(stateKey: K) =>
    (state: RouterState, action: PayloadAction<RouterState[K]>) => {
        const existingValue = state[stateKey];

        if (existingValue === action.payload) {
            return;
        }

        state[stateKey] = action.payload;
    };

export type RouterState = {
    activeTeam: string;
    deeplink: string;
    playId: string;
    communityPageNumber: number;
    communityPlayId: string;
    communityProfileId: string;
    communitySearchText: string;
    shownPlaybook: string;
    folderId: string;
    viewerDetailsPlayId: string;
    rosterPlayerId: string;
    editBillingEntityId: string;
    viewDocumentAcksId: string;
    viewPostId: string;

    invoiceLinkId: string;
    invoiceError: string;
    joinTeamCode: string;
    joinTeamId: string;
    emailAction: {
        mode: string;
        oobCode: string;
        apiKey: string;
        continueUrl: string;
    };
    shownScheduleTab: string;
    viewEventId: string;
    viewPracticeTemplateId: string;
    rsvpUpdateStatus: string;
    publicLinkId: string;
};

const routerDefaultState: RouterState = baseParamState;

const { reducer: routerReducer, actions: routerActions } = createSlice({
    name: 'router',
    initialState: routerDefaultState,
    reducers: {
        // TODO: explore if the use of these as unsetters is needed
        SetActiveTeam: createParamUpdater('activeTeam'),
        SetDeeplink: createParamUpdater('deeplink'),
        OpenPlay: createParamUpdater('playId'),
        OpenPage: createParamUpdater('communityPageNumber'),
        OpenCommunityPlay: createParamUpdater('communityPlayId'),
        SetCommunityProfileId: createParamUpdater('communityProfileId'),
        SetCommunitySearch: createParamUpdater('communitySearchText'),
        SetShownPlaybook: (state: RouterState, action: PayloadAction<string>) => {
            createParamUpdater('shownPlaybook')(state, action);
            state.folderId = null;
        },
        OpenPlayFolder: createParamUpdater('folderId'),
        ShowViewerDetails: createParamUpdater('viewerDetailsPlayId'),
        SetRosterPlayer: createParamUpdater('rosterPlayerId'),
        SetEditBillingEntity: createParamUpdater('editBillingEntityId'),
        ViewDocumentAcks: createParamUpdater('viewDocumentAcksId'),
        ViewPost: createParamUpdater('viewPostId'),
        SetViewEvent: createParamUpdater('viewEventId'),
        SetViewPracticeTemplate: createParamUpdater('viewPracticeTemplateId'),
        SetScheduleShownTab: createParamUpdater('shownScheduleTab'),

        UpdateRouterState: (state, action: PayloadAction<RouterState>) => {
            return action.payload;
        },
    },
    extraReducers: (builder) =>
        builder
            .addCase(playEditorActions.CreatePlay, (state, action) => {
                state.playId = action.payload.id;
            })
            .addCase(playEditorActions.NewPlaySaved, (state, action) => {
                state.playId = action.payload;
            }),
});

export { routerReducer, routerActions, routerDefaultState };
